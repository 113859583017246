
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import ContactForm from '../components/forms/ContactForm';
import { SiteConfig }  from '../SiteConfig';

function Contact() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Contact')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>

              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                  <h1>{t('Contact')}</h1>
                  <ContactForm/>
                </div>
                <div className="col-md-3">
                </div>
              </div>
          </div>
}

export default Contact;
