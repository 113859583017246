import React, { useEffect , useState, useRef, useCallback} from "react";
import { Table, Column, HeaderCell, Cell } from 'rsuite-table-fenix';
import RequirementsPagination from './RequirementsPagination';

import { Link, useHistory  } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import ButtonSpinner from '../spinners/ButtonSpinner';
import ErrorModal from '../modals/ErrorModal';
import MyListCSVDownload from '../MyListCSVDownload'
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';

import RequirementDetailsCarousel from '../modals/RequirementDetailsCarousel';

import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';

import { SiteConfig }  from '../../SiteConfig';

export default function RequirementsSelectedListGrid(props) {

  const upperPaginationRef = useRef(null);

  const [requirements, setRequirements] = useState([]);
  const [sortColumn, setSortColumn] = useState('standard_number');
  const [sortType, setSortType] = useState('asc');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE);
  const [pagesTotal, setPagesTotal] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(null);

  const [rowUpdating, setRowUpdating] = useState([]);
  const [remove_all_updating, setRemove_all_updating] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [canExportList, setCanExportList] = useState(false);
  const [canViewLinkedRequirements, setCanViewLinkedRequirements] = useState('false');
  const [showListSaveToFormModal, setShowListSaveToFormModal] = useState(false);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [selectedListReqIds, setSelectedListReqIds] = useState([]);
  const [canCreateLists, setCanCreateLists] = useState(false);
  const [listName, setListName] = useState("");
  const [selected_req_id, setSelected_req_id] = useState(null);
  const [selected_req_id_timestamp, setSelected_req_id_timestamp] = useState(null);

  const { t } = useTranslation();
  const {arrayAsBadges, docTypeIdToDocType} = Helpers();
  const history = useHistory();

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
    setPage(1);
    return true;
  }

  const lowerPageSizeEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    pageSizeEventHandler(page_size, true);
  }

  const pageSizeEventHandler  = (page_size, scrollToTop) => {
    setPageSize(page_size);
    setPage(1);
  }

  const lowerPaginationEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    paginationEventHandler(page_size, true);
  }

  const paginationEventHandler  = (page, scrollToTop) => {
    setPage(parseInt(page, 10));
  }

  const onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
    setShowListSaveToFormModal(false);
    setShowListFormModal(showAddListModal);
    if (reloadData)
      retrieveData();

  }

  const showListSaveToForm = (e,req_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListSaveToFormModal(true);
    setSelectedListReqIds([req_id]);
  }

  const onCloseListFormModalCallback = (reloadData) => {
    setShowListFormModal(false);
    setShowListSaveToFormModal(true);
  }

  const  navigateToLinkPage= (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/links/' + id);
  }

  const handleRemoveClick = (e, id) => {

    e.preventDefault();
    e.stopPropagation();

    let newRowUpdating = [...rowUpdating];
    newRowUpdating[id] = true;
    setRowUpdating(newRowUpdating);
    setLoadingData(true);

    requirementsService.deleteFromList(props.list_id, id, SiteConfig.docType)
      .then(
          (data) => {

            //newRowUpdating[id] = true;
            setLoadingData(false);
            setRowUpdating(newRowUpdating);
            let updatedRequirements = requirements.filter(x=> x.id !== id);

            receiveListInfo(listName, recordsTotal - 1);
            setRecordsTotal(recordsTotal - 1);

            if (0 === updatedRequirements.length) {
              if (pagesTotal === page) {
                setPage(page-1);
              } else {
                //trigger a fetch
                setPage(0);
                setPage(1);
              }
            } else {
              setRequirements(updatedRequirements);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setLoadingData(false);
              setShowErrorModal(true);
            }
          }
      );
  }

  const handleRemoveAllClick  = (e) => {
    e.preventDefault();
    e.stopPropagation()

    let row_updating = [...rowUpdating];
    let clnRequirements = [...requirements];

    let requirementIds = clnRequirements.map(item => {
        //row_updating[item.id] = true;
        return item.id;
    });

    clnRequirements.forEach((item, i) => {
      row_updating[item.id] = true;
    });

    setRemove_all_updating(true);
    setRowUpdating(row_updating);
    setLoadingData(true);

    requirementsService.deleteFromList(props.list_id, requirementIds)
      .then(
          (data) => {
            clnRequirements.forEach((item, i) => {
              row_updating[item.id] = false;
            });

            setRemove_all_updating(false);
            setRowUpdating(row_updating);

            if (pagesTotal === page) {
              setPage(page-1);
            } else {
              //trigger a fetch
              setPage(0);
              setPage(page);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            }
          }
      );
  }

  const receiveListInfo = props.receiveListInfo;
  const list_id = props.list_id;

  const retrieveData  =  useCallback(async () => {

      setLoadingData(true);
        requirementsService.postSelectedList(list_id, sortColumn, sortType, page, SiteConfig.docType, pageSize)
        .then(
            (result) => {
                setInitiallyLoaded(true);
                setRequirements(result.requirements.data);
                setPagesTotal(result.requirements.last_page);
                setRecordsTotal(result.requirements.total);
                setCanExportList(result.canexportlist);
                setListName(result.listName);
                receiveListInfo(result.listName, result.requirements.total);
                setCanCreateLists(result.canCreateLists);
                setCanViewLinkedRequirements(result.isAmasAdmin);
                setLoadingData(false);
            },
            error => {
              if (error.message === '401') {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShowErrorModal(true);
              }
            }
        );
      }
  ,[receiveListInfo,list_id, sortColumn, sortType, page, pageSize]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListSaveToFormModal show={showListSaveToFormModal} list_id={props.list_id} req_ids={selectedListReqIds} handleModalOpenClose={onCloseShowListSaveToFormModalCallback} />
      <ListFormModal show={showListFormModal}  handleModalClose={onCloseListFormModalCallback} />

      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
          <div className="container">
            { recordsTotal > 0 ?
            <><div className="col d-flex justify-content-center mb-3 d-print-none">
                <Button
                  className="mr-2"
                  variant="outline-primary"
                    onClick={(e) =>
                      {
                        window.print();
                      }
                    }
                  ><i className="fa fa-print"></i> {t('Print')}
                </Button>
                { canExportList && <MyListCSVDownload list_id={props.list_id} disabled={false}/>}
              </div>

              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="pull-right hasSpinner"
                  variant="danger"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <div
                  ref={upperPaginationRef}>
                  <RequirementsPagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
                </div>
              </div>
              <div className="col">
              <Table
                autoHeight={true}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                wordWrap
                onRowClick={data => {
                  //need to set a timestamp just in case the same row is clicked twice
                  setSelected_req_id(data.id);
                  setSelected_req_id_timestamp( new Date().valueOf());
                }}
                data={requirements}
                className="table-striped table-bordered table-hover table-list"
              >
              <Column sortable fixed resizable>
                 <HeaderCell>#</HeaderCell>
                 <Cell
                   className="text-col"
                   dataKey="standard_number">
                   {(rowData, rowIndex) => {
                     return  (
                       <>
                         <div className="hidden-tablet-landscape">
                           <OverlayTrigger
                             key={rowData.id}
                             placement="top"
                             overlay={
                               <Popover>
                                 <Popover.Content>
                                   <pre>{rowData.standard.title}</pre>
                                 </Popover.Content>
                               </Popover>
                             }
                             >
                             <span>{docTypeIdToDocType(rowData.standard.doc_type)} {rowData.standard.number}</span>
                           </OverlayTrigger>
                         </div>
                         <div className="visible-tablet-landscape">
                           <div>{t('Standard')}</div>
                           <span><strong>{docTypeIdToDocType(rowData.standard.doc_type)} </strong>:{rowData.standard_number} {rowData.standard.title}</span>
                         </div>
                       </>
                     )
                   }}
                 </Cell>
               </Column>
                <Column sortable>
                  <HeaderCell>{t('Section')}</HeaderCell>
                  <Cell dataKey="section" />
                </Column>
                <Column
                  resizable
                  verticalAlign="middle">
                  <HeaderCell>{t('Degree of Compliance')}</HeaderCell>
                  <Cell  dataKey="importance">

                    {(rowData, rowIndex) => {
                      return arrayAsBadges(rowData.importance.split(','),'importance');
                    }}
                  </Cell>
                </Column>
                <Column  sortable  flexGrow={1}>
                  <HeaderCell>{t('Text')}</HeaderCell>
                  <Cell
                    className="text-col  constrain-print"
                    dataKey="text"
                  >
                    {(rowData, rowIndex) => {
                      return  (
                        <>
                          <div className="hidden-tablet-landscape">
                          <OverlayTrigger
                            key={`text${rowData.id}`}
                            placement="top"
                            overlay={
                              <Popover className="wide">
                                <Popover.Content>
                                    <pre className="md-table">
                                      <MarkdownView
                                        markdown={rowData.text}
                                        options={{ tables: true, emoji: false }}
                                      />
                                    </pre>
                                </Popover.Content>
                              </Popover>
                            }
                            >
                            <span className="md-table">
                              <MarkdownView
                                markdown={rowData.text}
                                options={{ tables: true, emoji: false }}
                              />
                            </span>
                          </OverlayTrigger>
                          </div>
                          <div className="visible-tablet-landscape md-table">
                            <MarkdownView
                              markdown={rowData.text}
                              options={{ tables: true, emoji: false }}
                            />
                          </div>
                        </>
                      )
                    }}
                  </Cell>
                </Column>
                { false &&
                  <Column resizable>
                    <HeaderCell>{t('Stakeholders')}</HeaderCell>
                    <Cell dataKey="stakeholder">
                      {(rowData, rowIndex) => {
                        return arrayAsBadges(rowData.stakeholder.map(item => {
                            return item.title;
                        }) ,'primary');
                      }}
                    </Cell>
                  </Column>
                }
                <Column  resizable>
                  <HeaderCell></HeaderCell>
                  <Cell
                    dataKey="id"
                    className="break-spaces"
                    >
                    {(rowData, rowIndex) => {
                      return (
                              <>

                                <Button
                                  className="w-100 hasSpinner"
                                  variant="danger"
                                  size="sm"
                                  onClick={(e) => handleRemoveClick(e,rowData.id)}
                                  disabled={rowUpdating[rowData.id] || false}>
                                    {t('Remove')}
                                    {(rowUpdating[rowData.id] || false) && <ButtonSpinner/>}
                                </Button>
                                { canCreateLists &&
                                <Button
                                  className="mt-2 w-100"
                                  variant="primary"
                                  size="sm"
                                  onClick={(e) => showListSaveToForm(e,rowData.id)}
                                  >
                                  {t('Move')}
                                </Button>
                                }
                                { canViewLinkedRequirements && SiteConfig.siteTag !== 'iddrs' &&
                                  <Button
                                    className="mt-2 w-100"
                                    variant="success"
                                    size="sm"
                                    onClick={(e) => navigateToLinkPage(e,rowData.id)}
                                    disabled={!rowData.hasLink}>
                                      {t('Links')}
                                  </Button>
                                }
                              </>
                            )
                    }}
                  </Cell>
                </Column>
              </Table>
              </div>
              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="pull-right"
                  variant="danger"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <RequirementsPagination onPageSizeChange={lowerPageSizeEventHandler} onItemClick={lowerPaginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
              </div>
              <RequirementDetailsCarousel list_id={props.list_id} sort_column={sortColumn}  sort_type={sortType} page={page} page_size={pageSize} req_id={selected_req_id} req_id_timestamp={selected_req_id_timestamp}/>

            </>
            :
              <div className="col">{t('No List Items')} <Link className="underline" to="/search">{t('Search page')}</Link>.</div>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
