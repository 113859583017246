import React, { useEffect,  useState}  from 'react';
import {Helmet} from "react-helmet";
import Badge from 'react-bootstrap/Badge'
import { useTranslation } from 'react-i18next';
import RequirementsListGrid from '../components/grids/RequirementsListGrid';

import { myListCounterService } from '../services/myListCounterService';
import { SiteConfig }  from '../SiteConfig';

function MyLists() {

  const { t } = useTranslation();
  const [myListCount, setMyListCount] = useState(0);

  useEffect(() => {
    const unsubscribe = myListCounterService.currentMyListCount.subscribe(count => {
      setMyListCount(count);
    });

    return unsubscribe;

  },[]);

  return (
    <div>
        <Helmet>
            <title>{t('My Lists')}</title>
            <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
        </Helmet>
        <div className="controls">
          <div className="container">
            <div className="col">
              <h1>{t('My List')} <Badge variant="info">{myListCount} {t('results')}</Badge></h1>
            </div>
          </div>
        </div>
        <RequirementsListGrid/>
    </div>
  );
}

export default MyLists;
