import React from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import { SiteConfig }  from '../SiteConfig';


import NotificationsGrids from '../components/grids/NotificationsGrids';

function Notifications() {

    const { t } = useTranslation();
    const { id } = useParams()

    return <div>
            <Helmet>
                <title>{t('Notifications')}</title>
                <bodyAttributes class={'fill-bg ' + SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Notifications')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
              <NotificationsGrids req_id={id}/>
            </div>
            </div>
          </div>
}

export default Notifications;
