import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import RegisterForm from '../components/forms/RegisterForm';

import { SiteConfig }  from '../SiteConfig';

function Register() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Create account')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Create account')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-md-10 offset-md-1">
                <RegisterForm/>
              </div>
            </div>
          </div>
}

export default Register;
