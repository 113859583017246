
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { SiteConfig }  from '../SiteConfig';
import Helpers from '../components/Helpers'


function Terms() {
    const { t } = useTranslation();

    const {createMarkup} = Helpers();

    return <div>
            <Helmet>
                <title>{t('Terms')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Terms & conditions of use')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
                <span dangerouslySetInnerHTML={createMarkup(t('Terms Page'))}/>
              </div>
            </div>
          </div>
}

export default Terms;
