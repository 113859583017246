
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import LoginForm from '../components/forms/LoginForm';
import { SiteConfig }  from '../SiteConfig';

function Login() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Login')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Login')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-md-10 offset-md-1">
                <LoginForm/>
              </div>
            </div>
          </div>
}

export default Login;
