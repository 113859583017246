
import React, { useEffect , useState} from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Collapse  from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';

import Helpers from '../components/Helpers'

function CookieConsent(props) {

  const { t } = useTranslation();
  const {createMarkup,setCookieEU,getCookieEU} = Helpers();
  const [open, setOpen] = useState(false);


  useEffect(() => {
    var consent = getCookieEU("cookies_consent");
    setOpen((consent === null || consent === "" || consent === undefined));
  },[getCookieEU]);

  const acceptCookies = () => {
    setCookieEU("cookies_consent", 1, 30);
    setOpen(false);
  }

  return (
    <Collapse in={open} timeout={500}>
      <div className="row">
        <div className="col-md-4 col-sm-12 cookie-consent">
         <div className="p-3 pb-4 text-white">
           <div className="row">
             <div className="col-12 d-flex justify-content-between align-items-center pb-2">
               <h2 className="text-left">{t('Accept Cookies')}</h2>
              { false &&  <i
                onClick={e => setOpen(false)}
               className="fa fa-times"></i> }
             </div>
           </div>
           <p>
            <span dangerouslySetInnerHTML={createMarkup(t('We DO NOT currently use advertising or personalised tracking within our application. We only use cookies to store data which is strictly necessary for our site to function. By using our website you are consenting to our use of cookies in accordance with our'))}/>{' '}
            <Link className="underline" to="/cookies">{t('Cookie Policy')}</Link>.
           </p>
           <Button
            onClick={acceptCookies}
            variant="danger"
            className="w-100"
            >{t('Accept Cookies')}</Button>
         </div>
        </div>
      </div>
    </Collapse>
  )
};


export default CookieConsent;
