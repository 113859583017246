import React, { useEffect , useState, useCallback } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonSpinner from '../spinners/ButtonSpinner';
import LoadingSpinner from '../spinners/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { Formik, Form, useFormik } from 'formik';

import * as Yup from "yup";

import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';

function ListSaveToForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [cmLists, setCmLists] = useState([]);
  const [selectedCmLists, setSelectedCmLists] = useState([]);
  const [ show, setShow ] = useState(false);
  const [canCreateLists, setCanCreateLists] = useState(false);

  const dropDownItemClick = (e,element) => {
    e.preventDefault();
    e.stopPropagation();

    const temp = [...selectedCmLists];

    var foundIdx = -1;

    temp.map((key,index) => {
      if (key.id === element.id)
        foundIdx = index;

      return 0;
    })

    if (-1 === foundIdx) {
      temp.push(element)
    } else {
      temp.splice(foundIdx,1)
    }

    setSelectedCmLists(temp);
  }


  const validationSchema=Yup.object().shape({

  })

  const formik = useFormik({
     enableReinitialize: true,
     initialValues: {
       list_name: '',
     },
     validationSchema: validationSchema,
     onSubmit: async (values) => {

       setFeedbackText('');
       setSubmitting(true);

       if (1<props.req_ids.length) {

         listsService.saveAllRequirementsToLists(props.req_ids, selectedCmLists.map(element => element.id))
           .then(
               user => {
                 setSubmitting(false);
                 props.onSuccessfulFormSubmit(true);
               },
               error => {
                 setSubmitting(false);
                 setFeedbackText(t('Save to list error'));
               }
           );

       } else {
         listsService.saveRequirementToLists(props.req_ids, selectedCmLists.map(element => element.id))
           .then(
               user => {
                 setSubmitting(false);
                 props.onSuccessfulFormSubmit(true);
               },
               error => {
                 setSubmitting(false);
                 setFeedbackText(t('Save to list error'));
               }
           );
         }
       }

   });

   const addNewList = () => {
     props.onSuccessfulFormSubmit(false,true);
   }

   const retrieveData  = useCallback(async () => {

     listsService.getListsForReqId(1 === props.req_ids.length ? props.req_ids[0] : -1 )
     .then(
         (result) => {
           setInitiallyLoaded(true);
           //we are filtering returning data for the move facillity
           //as we dont want to display the current list or any lists that the requirement
           //already belongs to .
           let resultsInLists = result.inlistids.filter(list => list.id !== props.list_id);
           let resultsCMLists = (!props.list_id) ? result.lists : result.lists.filter(function(list) {
             return list.id !== props.list_id && 0 === resultsInLists.filter(function(inlist) {
               return inlist.id === list.id
             }).length;
           });

           setCmLists(resultsCMLists);
           setSelectedCmLists(resultsInLists);
           setCanCreateLists(result.canCreateLists);
         },
         error => {

           if (error.message === '401' ) {
             authenticationService.logout();
           } else if (error.message === '500') {
             //setShowErrorModal(true);
           } else if (error.message === '403') {
            // history.push('/');
           }

         }
     );
  },[props.req_ids]);

   useEffect(() => {
     retrieveData();
  },[props.req_ids, retrieveData]);

  return (
      <>
      { initiallyLoaded  ?
        <>
          {feedbackText && <Alert variant="danger">
            {feedbackText}
          </Alert>}
          <Formik>
            <>
              <Form  onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <div className="d-flex">
                    <div className="mr-md-4 flex-grow-1">

                      <Dropdown
                        className="custom-dropdown"
                        onClick={(e) => e.stopPropagation()}
                        show = {show}
                        >
                        <Dropdown.Toggle
                          size="md"
                          className="w-100"
                          onClick = {() => setShow(!show)}
                        >
                          <div>{t('Select one or more lists')} ...</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          size="md"
                          className="w-100"
                        >
                        {cmLists.sort((a, b) => a.list_name - b.list_name).map(element =>
                             <Dropdown.Item
                               active={selectedCmLists.map((item) => item.id).indexOf(element.id) >= 0}
                               key={element.id}
                               onClick={(e) => dropDownItemClick(e,element)}
                             >
                             {element.list_name}
                             </Dropdown.Item>
                         )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <Button
                      className="mb-4 align-self-center flex-basis-90"
                      variant="primary"
                      size="md"
                      disabled={submitting}
                      type="submit"
                    >
                      {t('Save')} {submitting && <ButtonSpinner/>}
                    </Button>
                </div>
              </div>
            </Form>
            { canCreateLists &&
              <div className="form-group">
                <Button
                  className="w-100"
                  variant="primary"
                  size="md"
                  disabled={submitting}
                  type="submit"
                  onClick={addNewList}
                >
                  {t('Add new list')}
                </Button>
              </div>
            }
          </>
        </Formik>
      </>
      :
     <LoadingSpinner fixedHeight={135}/>
    }
    </>

  );
};

export default ListSaveToForm;
