


let docType = 1;
let siteTag = 'mineaction';
let recapchaV3SiteKey = process.env.REACT_APP_RECAPCHA_V3_SITE_KEY_MINEACTION;

/*
let docType = 3;
let siteTag = 'afghanistan';
*/

if (window.location.hostname.indexOf('mineaction') !== -1) {
  docType = 1;
  siteTag = 'mineaction';
}

if (window.location.hostname.indexOf('ammunitionmanagement') !== -1
  || window.location.hostname.indexOf('d3gmkh7va5h02h') !== -1
  || window.location.hostname.indexOf('dgler3jhqz9w8') !== -1) {
  docType = 2;
  siteTag = 'ammunitionmanagement';
}

if (window.location.hostname.indexOf('afghanistan') !== -1
  || window.location.hostname.indexOf('d3h7hlb18x4nwa') !== -1
  || window.location.hostname.indexOf('amas') !== -1) {
  docType = 3;
  siteTag = 'afghanistan';
}

if (window.location.hostname.indexOf('iddrs') !== -1
  || window.location.hostname.indexOf('d8k28r13zsreh') !== -1
  || window.location.hostname.indexOf('iddrs') !== -1) {
  docType = 4;
  siteTag = 'iddrs';
}



export const SiteConfig = { docType: docType, siteTag: siteTag, recapchaV3SiteKey: recapchaV3SiteKey};
